import { EnvironmentInterface } from '../definitions/environment.interface';

export const environment: EnvironmentInterface = {
  name: 'dev',
  production: true,
  apiPath: 'api',

  // Sentry settings
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  showDialog: true,

  // reCAPTCHA key
  recaptchaKey: '6Lef8xIqAAAAAMynaWp-aBza-A9pICJH1QEH6MwH'
};
